@use '../config/' as *;
.waf-statslisting {
    padding: 0 var(--space-1);
    // margin-inline: 0;
    .swiper-button-next,
    .swiper-button-prev {
        @extend %d-none;
    }
    .waf-head {
        min-height: 10.3rem;
    }
    .waf-head,
    .waf-body {
        @extend %px-2;
    }
}
// .filter-section {
//     top: -9rem;
// }
.stats {
    &-main-wrap {
        .stats-wrapper {
            @extend %mx-3-neg;
        }
    }
    &-item {
        margin-right: 1.7rem;
    }
    &-list {
        @include card-count(1.2);
        @extend %pl-3;
        overflow-x: scroll;
    }
    &-main-wrap {
        @include separator(100%, x, 0.1rem, secondary, 1);
        &:last-child {
            &::after {
                display: none;
            }
        }
        @extend %relative;
        @extend %py-6;
        &.club {
            .stats-list-item {
                &:first-child {
                    .stats-content {
                        flex: 0 0 55%;
                    }
                    .stats-thumbnail {
                        aspect-ratio: 1/1;
                        height: 9.7rem;
                        @extend %p-3;
                        @extend %medium-grey-bg;
                        @extend %circle-radius;
                    }
                    .meta-logo {
                        @extend %relative;
                        .image {
                            @extend %d-none;
                        }
                        &::after {
                            @include icon(stadium, 12);
                            @extend %secondary;
                            @extend %flex-c-c;
                            @extend %position-center;
                        }
                    }
                }
                &:not(:first-child) {
                    @extend %py-1;
                    .stats-thumbnail {
                        height: 3.4rem;
                        @extend %p-1;
                        @extend %medium-grey-bg;
                        @extend %circle-radius;
                    }
                }
                &:last-child {
                    @extend %py-3;
                }
            }
        }
        &.awards {
            .swiper {
                &-button-next,
                &-button-prev {
                    display: none;
                }
            }
        }
    }
    &-main-title {
        @extend %font-20-pb;
        @extend %uppercase;
    }
    &-list-title {
        @extend %font-12-pb;
        @extend %uppercase;
    }
    &-list {
        &-item {
            @extend %flex-se-c;
            a {
                &:hover {
                    text-decoration: underline;
                }
            }
            &:first-child {
                background: linear-gradient(356.76deg, var(--club-primary) -80.63%, var(--club-secondary) 48.47%);
                border-radius: var(--quarter-radius) var(--quarter-radius) 0 0;
                height: 13.5rem;
                @extend %pt-1;
                @extend %flex-sb-c;
                .stats-thumbnail {
                    @extend %h-100;
                    @extend %ml-3;
                    .image {
                        @extend %h-100;
                    }
                }
                .stats-content {
                    flex: 0 0 55%;
                    @extend %pr-2;
                }
                .stats-name {
                    @extend %white;
                    &:hover {
                        text-decoration: underline;
                    }
                    .name {
                        line-height: 1.8rem;
                        @extend %font-16;
                        @extend %uppercase;
                        @extend %white;
                        &.first-name {
                            font-weight: 400;
                        }
                        &.last-name {
                            font-weight: 700;
                        }
                    }
                }
                .meta {
                    &-logo {
                        width: 3rem;
                        height: 3rem;
                        flex-shrink: 0;
                        @extend %white-bg;
                        @extend %circle-radius;
                        .image {
                            padding: 0.3rem;
                            @extend %w-100;
                            @extend %h-100;
                            @extend %flex-c-c;
                        }
                    }
                    &-text {
                        @extend %font-8-pr;
                        @extend %capitalize;
                        @extend %white;
                    }
                    &-value {
                        line-height: 3.6rem;
                        @extend %font-32-pb;
                        @extend %white;
                    }
                }
            }
            &:not(:first-child) {
                border: .1rem solid hsl(var(--hsl-secondary-dark) / .1);
                @extend %white-bg;
                @extend %px-3;
                .stats-thumbnail {
                    aspect-ratio: 1/1;
                    height: 4.6rem;
                    .image {
                        @extend %h-100;
                    }
                }
                .stats-content {
                    flex: 0 0 80%;
                    @extend %pl-3;
                    @extend %flex-sb-c;
                }
                .stats-name {
                    max-width: 8rem;
                    @extend %secondary-light;
                    .name {
                        line-height: 1.2rem;
                        @extend %font-10;
                        @extend %uppercase;
                        @extend %secondary-light;
                        &.first-name {
                            font-weight: 400;
                        }
                        &.last-name {
                            font-weight: 700;
                        }
                    }
                }
            }
        }
        &-footer {
            border-radius: 0 0 var(--half-radius) var(--half-radius);
            @extend %white-bg;
            @extend %p-3;
        }
    }
    &-anchor {
        @include border(1, secondary-light, 5);
        @extend %font-12-pm;
        @extend %d-block;
        @extend %py-2;
        @extend %w-100;
        @extend %text-center;
        @extend %half-radius;
        @extend %capitalize;
        &:hover {
            border: none;
            transition: all 500ms;
            @extend %primary-bg;
            @extend %white;
        }
    }
}
.meta {
    &-info {
        @extend %gap-1;
        @extend %my-2;
        @extend %flex-n-c;
        @extend %relative;
        &::after {
            content: "";
            height: 0.1rem;
            @extend %w-100;
            @extend %white-bg-1;
            @include position(null, 0, -0.8rem, null);
        }
    }
    &-logo {
        width: 1.8rem;
        height: 1.8rem;
        @extend %white-bg;
        @extend %circle-radius;
        .image {
            width: 100%;
            height: 100%;
            padding: .3rem;
            @extend %flex-c-c;
        }
    }
    &-text {
        @extend %font-8-pr;
        @extend %capitalize;
        @extend %white;
    }
    &-value {
        line-height: 2rem;
        @extend %font-20-pb;
        @extend %secondary-dark;
    }
}
@include mq(col-md) {
    // .filter-section {
    //     top: -12rem;
    // }
    .waf-statslisting {
        .waf-head,
        .waf-body {
            padding-inline: 0;
        }
        .waf-head {
            min-height: 12.8rem;
        }
        .swiper-button-prev,
        .swiper-button-next {
            height: max-content;
        }
    }
    .stats {
        &-main-wrap {
            .stats-wrapper {
                margin-inline: var(--space-5-neg);
            }
        }
        &-list {
            padding-left: var(--space-5);
            @include card-count(2.3);
            overflow-x: unset;
        }
        &-main-wrap {
            &.club {
                .stats-list-item {
                    &:first-child {
                        .stats-content {
                            flex: 0 0 50%;
                        }
                    }
                }
            }
        }
        &-list-item {
            &:first-child {
                .stats-content {
                    flex: 0 0 55%;
                }
            }
            &:not(:first-child) {
                padding-inline: var(--space-2);
            }
        }
    }
}
@include mq(col-lg) {
    .waf-statslisting {
        .swiper-button {
            &-next,
            &-prev {
                display: flex;
                background-color: hsl(var(--hsl-secondary)/1);
                opacity: 1;
                top: 4rem;
                width: 2.6rem;
                &::after {
                    color: var(--white);
                }
            }
            &-prev {
                left: 0;
            }
            &-next {
                right: 0;
            }
            &-disabled {
                &::after {
                    color: hsl(var(--hsl-white)/.5);
                }
            }
        }
    }
    .stats {
        &-list {
            padding-left: 0;
            @include card-count(4.1);
            overflow-x: unset;
        }
        &-main-wrap {
            .stats-wrapper {
                margin-inline: var(--space-3-neg);
                padding-inline: var(--space-3);
            }
        }
    }
}