@use '../config/' as *;
.waf-breadcrumb {
    @extend %py-0;
}
.webview {
    .waf-statslisting {
        .filter-section {
            top: 2rem;
        }
        .title {
            margin-top: 2rem
        }
    }
    .info-section {
        top: 16rem
    }
    .stats-header {
        top: 10.3rem
    }
}
.waf-statslisting {
    .layout-wrapper {
        @extend %relative;
    }
    .waf-head {
        isolation: unset;
    }
    .head-wrap {
        @extend %w-100;
        @extend %pb-10;
        & > .title {
            height: 5rem;
            @extend %font-18-pb;
            @extend %uppercase;
        }
    }
}
@include mq(col-md) {
    .waf-statslisting {
        .head-wrap > .title {
            font-size: 3rem;
        }
    }
}