@use "../config" as *;
@forward "../swiper/swiper-bundle";
.waf-component {
    &.waf-banner,
    &.waf-app-banner {
        @extend %p-0;
        @extend %my-0;
        @extend %mx-2-neg;
    }
}
.waf-app-banner,
.waf-banner {
    @extend %relative;
    @extend %medium-grey-bg;
    .layout-wrapper {
        @extend %px-3;
        @extend %my-4;
    }
}
.waf-app-banner {
    isolation: isolate;
    overflow: hidden;
    &::after {
        content: "";
        width: calc(var(--window-inner-width) * 1.28);
        aspect-ratio: 2.05;
        z-index: var(--z-negative);
        pointer-events: none;
        @include position(-5%, null, null, 30%);
    }
    .app-banner-wrap {
        background: linear-gradient(107.96deg, var(--secondary-dark) 37.53%, var(--primary) 174.87%);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        @extend %pb-6;
    }
    .image-wrap {
        width: 80%;
        @extend %m-auto;
    }
    .content-wrap {
        @extend %px-3;
        @extend %mb-6;
    }
    .btn-wrap {
        @extend %px-3;
    }
    .title {
        @include border(1, white, 2, bottom, dashed);
        @extend %font-20;
        @extend %accent;
        @extend %text-center;
        @extend %uppercase;
        @extend %pb-3;
        @extend %mb-3;
    }
    .content {
        @extend %font-12;
        @extend %white-7;
        @extend %text-center;
    }
    .btn-wrap {
        @extend %flex-c-c;
        @extend %gap-4;
    }
    .store {
        height: 4.6rem;
        .btn-image {
            @extend %h-100;
        }
    }
}
.waf-poll-banner {
    &.waf-component {
        margin-block: var(--space-4) var(--space-10);
        @extend %p-0;
    }
    .btn-fill {
        position: absolute;
        left: 50%;
        bottom: 4rem;
        translate: -50% 0;
        // @include position-combo(x-center, null, 5rem);
    }
    .swiper-button-prev,
    .swiper-button-next {
        @extend %d-none;
    }
    .swiper-pagination-bullet {
        opacity: 1;
        @extend %light-grey-bg;
    }
}
.waf-banner {
    .layout-wrapper {
        margin-bottom: var(--space-6);
    }
    .banner {
        overflow-x: clip;
        overflow-y: visible;
    }
    .swiper-pagination {
        --swiper-pagination-bullet-inactive-color: hsl(var(--hsl-secondary-light) / 1);
        bottom: -2rem;
        left: 50%;
        transform: translate(-50%, 0);
    }
    .banner-list.swiper-wrapper {
        font-size: 0;
    }
}
@media screen and (min-width: $tablet-min-width) {
    .waf-component {
        &.waf-poll-banner {
            margin-block: var(--space-0);
        }
    }
    .waf-app-banner,
    .waf-banner {
        .layout-wrapper {
            margin-block: var(--space-12);
            padding-inline: 0;
        }
    }
    .waf-app-banner {
        .layout-wrapper {
            margin-top: 7rem;
        }
        &::after {
            width: 64%;
            aspect-ratio: 2.07;
            @include position(unset, null, 30%, 70%);
        }
        .app-banner-wrap {
            background-size: 100% 100%;
            background-repeat: no-repeat;
            position: relative;
            padding: var(--space-3);
            @include flex-config(flex, row, null, center);
        }
        .image-wrap {
            width: 36.5rem;
            @include position(null, null, 0, 0);
        }
        .content-wrap {
            width: 47%;
            padding-inline: 32.5rem 3.5rem;
            flex-grow: 1;
            margin-bottom: 0;
        }
        .btn-wrap {
            padding: var(--space-9) 0;
            border-radius: var(--space-2);
            width: 25rem;
            background-color: hsl(var(--hsl-white) / 0.1);
            @include flex-config(flex, column, center, null);
        }
        .title {
            font: 3rem/3.4rem $font-primary;
            font-weight: 700;
            text-align: left;
        }
        .content {
            text-align: left;
        }
        .store {
            height: 5.5rem;
        }
    }
    .waf-row-poll-bg {
        padding-block: var(--space-12);
        position: relative;
        isolation: isolate;
        overflow: hidden;
        &::after {
            content: "";
            width: 64%;
            aspect-ratio: 2.07;
            z-index: var(--z-negative);
            pointer-events: none;
            @include position(null, null, 30%, 70%);
        }
    }
}
@media screen and (min-width: $tablet-min-width) and (max-width: $desktop-min-width) {
    .waf-app-banner {
        .layout-wrapper {
            margin-top: 8rem;
        }
    }
    .waf-app-banner {
        .app-banner-wrap {
            .image-wrap {
                width: 27rem;
                @include position(null, null, 2rem, 0);
            }
            .content-wrap {
                padding-inline: 23rem 2rem;
            }
            .btn-wrap {
                padding: var(--space-3) var(--space-2);
                width: 13.5rem;
            }
            .title {
                font: 2rem/2.6rem $font-primary;
                font-weight: 700;
            }
            .store {
                height: 4.5rem;
            }
        }
    }
}